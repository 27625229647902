<template>
  <div>
    <click-to-edit
      v-model="scale.fact"
      :disabled="disabled"
      placeholder=""
      digits-only
      @input="$emit('change', scale.fact)"
    ></click-to-edit>
  </div>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import ClickToEdit from "@/components/ClickToEdit";

export default {
  name: "quantity-card-fact-view",
  mixins: [dynamicScaleComponent],
  components: {ClickToEdit},

  props: {
    disabled:{type: Boolean, default: false}
  },
  data() {
    return {
      scale:{
        fact: null,
      },
    }
  },
  methods: {}
}
</script>